import axios from "axios";

let host = "https://api-newpr021.codifyer.io/admin/";

let url = {
    table_1: host + 'table_1',
    table_2: host + 'table_2',
}

let Request = {
    table_1: {
        get: (body) => {
            return request('get', url.table_1, body);
        },
        post: (body) => {
            return request('post', url.table_1, body);
        },
        put: (body) => {
            return request('put', url.table_1, body);
        },
        delete: (body) => {
            return request('delete', url.table_1, body);
        }
    },
    table_2: {
        get: (body) => {
            return request('get', url.table_2, body);
        },
        post: (body) => {
            return request('post', url.table_2, body);
        },
        put: (body) => {
            return request('put', url.table_2, body);
        },
        delete: (body) => {
            return request('delete', url.table_2, body);
        }
    }
};

const request = (method, url, data) => {

    let options = {
        url,
        method,
        timeout: 10000,
        headers: {
            "content-type": "application/json"
        },
    }

    if (localStorage.getItem("token")) {
        options.headers["Authorization"] = "Bearer " + localStorage.getItem("token");
    }

    if (method == "get") {
        options.params = data;
    } else {
        options.data = data;
    }

    return axios(options).catch((error) => {
        throw new Error(error.response.data);
    });

};

export default Request;