import { Route, Routes } from "react-router-dom";

import { WelcomePage, Table_1Page, Table_2Page } from "./pages/index";

import Root from "./layout/Root";

import "antd/dist/antd.css";

function App() {
    return (
        <div className="App">
            <Routes>
                <Route path="/" element={<WelcomePage />} />
                <Route path="/dashboard" element={<Root />}>
                    <Route path="/dashboard/table_1" element={<Table_1Page />} />
                    <Route path="/dashboard/table_2" element={<Table_2Page />} />
                </Route>
            </Routes>
        </div>
    );
}

export default App;
