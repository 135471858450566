import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Layout, Menu } from "antd";

import "./_Sidebar.scss";

export default function Sidebar({ collapsed }) {
    const navigate = useNavigate();

    const location = useLocation();

    const [current, setCurrent] = useState("");

    const onClickItem = (e) => {
        setCurrent(e.key);
        navigate(e.key);
    };

    useEffect(() => {
        setCurrent(location.pathname);
    }, []);

    return (
        <Layout.Sider
            id="sidebar"
            collapsible={null}
            collapsed={collapsed}
            collapsedWidth={80}
            width={250}
        >
            <div className="sidebar-logo-section">
                <div className="sidebar-logo"></div>
            </div>

            <div className="sidebar-menu">
                <Menu
                    mode="inline"
                    selectedKeys={current}
                    onClick={onClickItem}
                    items={[
                        {
                            key: "/dashboard/table_1",
                            label: "Table 1",
                        },
                        {
                            key: "/dashboard/table_2",
                            label: "Table 2",
                        },
                    ]}
                />
            </div>
        </Layout.Sider>
    );
}
